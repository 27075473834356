// React
import React from "react";

// Material
import { ListItemIcon } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

// Image
import iconWarning from "../../assets/ic-warning.svg";

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: "fixed",
      zIndex: 1000,
      width: "100%",
      backgroundColor: "#b9bfce",
      color: "white",
      fontSize: 14,
      display: "flex",
      alignItems: "center",
      padding: 14,
      [theme.breakpoints.up("md")]: {
        paddingLeft: "10%",
        paddingRight: "10%"
      }
    }
  })
);

/**
 * Module locked bloc
 */
const ModuleLockedBlocBanner = () => {
  /** Classes  */
  const classes = useStyles();

  /** Locked message */
  const lockedMessage: string =
    "<b>Pour accéder à vos activités, commencez par évaluer vos connaissances.</b>";

  return (
    <div className={classes.container}>
      <ListItemIcon>
        <img src={iconWarning} alt="icon-warning" />
      </ListItemIcon>
      <div dangerouslySetInnerHTML={{ __html: lockedMessage }} />
    </div>
  );
};

export default React.memo(ModuleLockedBlocBanner);
