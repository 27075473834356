/**
 * Get second formatted with formt xx'j' xx'h' xx'min'
 * @param seconds
 */
export const getSecondsFormatted = (
  seconds: number,
  full: boolean = false
): string => {
  let delta = seconds;
  const hours = Math.floor(delta / 3600);
  delta -= hours * 3600;
  const minutes = Math.floor(delta / 60) % 60;

  if (full) {
    return `${hours > 0 ? `${hours} h ` : ""}${
      minutes < 10 ? 0 : ""
    }${minutes} min`;
  }

  return `${hours > 0 ? `${hours}h` : ""}${minutes < 10 ? 0 : ""}${minutes}${
    hours < 1 ? "min" : ""
  }`;
};

/**
 * Get inverse format
 * @param date
 */
export const getInverseFormat = (date: string): string => {
  const separator = "/";
  if (date) {
    date = date.replace(/-/g, "/");
    const dateArray = date.split(separator);
    return dateArray.length === 3
      ? dateArray[2] + separator + dateArray[1] + separator + dateArray[0]
      : date;
  }
  return date;
};

/**
 * Format date for API RF
 * Use on POST request /timesElapsed
 * @param date
 */
export const formatDateForApi = (date: number) =>
  new Date(date)
    .toISOString()
    .slice(0, 19)
    .replace("T", " ");

/**
 * Formatted date by dd/mm/yyyy
 * @param timestamp
 * @param separator
 * @param format
 */
export const formattedDate = (
  date: Date,
  separator: string = "/",
  format: string = "dd/mm/yyyy"
): string => {
  let month = String(date.getMonth() + 1);
  let day = String(date.getDate());
  const year = String(date.getFullYear());
  month = month.length < 2 ? (month = "0" + month) : month;
  day = day.length < 2 ? (day = "0" + day) : day;

  switch (format) {
    case "yyyy/mm/dd":
      return `${year}${separator}${month}${separator}${day}`;
    case "yyyy/mm":
      return `${year}${separator}${month}`;
    case "mm/yyyy":
      return `${month}${separator}${year}`;
    default:
      return `${day}${separator}${month}${separator}${year}`;
  }
};
