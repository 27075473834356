// React
import React from "react";

// Material
import { ListItemIcon } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

// Colors
import { colorCAC } from "../../styles/color";

// Image
import iconWarning from "../../assets/ic-warning.svg";

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      zIndex: 2,
      backgroundColor: colorCAC.red,
      color: "white",
      fontSize: 14,
      display: "flex",
      alignItems: "center",
      padding: 10,
      [theme.breakpoints.up("md")]: {
        paddingLeft: "10%",
        paddingRight: "10%"
      }
    }
  })
);

/**
 * Module expired banner
 */
const ModuleExpiredBanner = () => {
  /** Classes  */
  const classes = useStyles();

  /** Expired message */
  const expiredMessage: string =
    "<b>Les activités de ce module ne sont plus comptabilisées</b> dans votre temps de formation de l’année en cours. Vous pouvez néanmoins accéder à chacune des activités de ce module.";

  return (
    <div className={classes.container}>
      <ListItemIcon>
        <img src={iconWarning} alt="icon-warning" />
      </ListItemIcon>
      <div dangerouslySetInnerHTML={{ __html: expiredMessage }} />
    </div>
  );
};

export default React.memo(ModuleExpiredBanner);
