// React
import React from "react";

// Material
import { Box, Grid, Icon, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    nbActivities: {
      color: "#8a8a8a",
      fontSize: 14
    },
    time: {
      color: "#263c46",
      fontWeight: 800,
      fontSize: 12,
      marginLeft: theme.spacing(2)
    },
    tabBloc: {
      backgroundColor: "transparent"
    },
    title: {
      textAlign: "left"
    },
    icon: {
      width: "auto",
      height: "auto"
    }
  })
);
/**
 * ModuleTab props
 */
interface IModuleTabProps {
  title: string;
  nbActivities: number;
  percentage: number;
  icon?: string;
  mobile?: boolean;
}

/**
 * ModuleTab component
 */
const ModuleTab = ({
  title,
  nbActivities,
  percentage,
  icon,
  mobile
}: IModuleTabProps) => {
  /** Classes  */
  const classes = useStyles();

  return (
    <div className={classes.tabBloc}>
      <Grid container={true}>
        {icon ? (
          <Icon className={classes.icon}>
            <img src={icon} alt="icon" />
          </Icon>
        ) : null}
      </Grid>
      <Box
        fontWeight="600"
        color="textPrimary"
        fontSize="subtitle1.fontSize"
        className={classes.title}
        style={{ textAlign: mobile ? "center" : "left" }}
      >
        {title}
      </Box>
      <Grid
        container={true}
        alignItems="baseline"
        style={{ justifyContent: mobile ? "center" : "left" }}
      >
        <Grid item={true}>
          <Typography
            variant="body1"
            component="h2"
            className={classes.nbActivities}
          >
            {nbActivities} activités
          </Typography>
        </Grid>
        <Grid item={true}>
          <Typography
            variant="subtitle2"
            noWrap={true}
            className={classes.time}
          >
            {percentage}%
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default React.memo(ModuleTab);
