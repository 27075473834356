// React
import React from "react";
import { withRouter, RouteComponentProps } from "react-router";

// Material
import { Paper, makeStyles, createStyles, Fab } from "@material-ui/core";

// Models
import { Quiz } from "../../models/activity.model";
import { colorCAC } from "../../styles/color";

// Bloc helper
import { getStarterQuizImage } from "../../helpers/bloc.helper";

/**
 * Styles
 */
const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      padding: 40,
      paddingTop: 20,
      marginTop: 30,
      display: "flex",
      justifyContent: "flex-start",
      flexWrap: "wrap",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column-reverse"
      }
    },
    startBtn: {
      background: colorCAC.greenRF,
      color: "#fff",
      fontWeigh: "bold",
      padding: "12px 47px",
      marginTop: 15,
      fontSize: 16
    },
    landingBloc: {
      paddingTop: 20,
      fontWeight: "bold",
      [theme.breakpoints.down("md")]: {
        width: "100%"
      },
      [theme.breakpoints.up("md")]: {
        width: "50%"
      }
    },
    mainText: {
      fontSize: 24,
      marginTop: 0
    },
    secondaryText: {
      fontSize: 14
    },
    svg: {
      textAlign: "right",
      mawWidth: "100% !important",
      [theme.breakpoints.down("md")]: {
        textAlign: "center"
      }
    }
  })
);

/** Quiz props */
interface IStarterQuizCardProps {
  quizActivity: Quiz;
  blocSlug: string;
}

/**
 * Quizz component
 */
const StarterQuizCard = ({
  quizActivity,
  blocSlug,
  match,
  history
}: RouteComponentProps & IStarterQuizCardProps) => {
  const classes = useStyles();
  const landingQuizSvg = getStarterQuizImage(blocSlug);

  return (
    <Paper className={classes.root}>
      <div className={classes.landingBloc}>
        <p className={classes.mainText}>
          Pour accéder à vos activités, commencez par évaluer vos connaissances.
        </p>
        <p className={classes.secondaryText}>Quelques minutes seulement</p>
        <Fab
          variant="extended"
          size="large"
          className={`${classes.startBtn}`}
          onClick={() => {
            history.push(match.url + "/positionnement/" + quizActivity._id);
          }}
        >
          {quizActivity.bookmark && quizActivity.bookmark?.length > 0
            ? "Terminer mon test"
            : "Démarrer"}
        </Fab>
      </div>
      <div className={`${classes.landingBloc} ${classes.svg}`}>
        <img src={landingQuizSvg} alt="Quiz de positionnement" />
      </div>
    </Paper>
  );
};

export default withRouter(React.memo(StarterQuizCard));
