// React
import React from "react";

// Material
import { Grid, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";

// Helpers
import { getSecondsFormatted } from "../../helpers/date-fomatter.helper";

// Colors
import { colorCAC } from "../../styles/color";

/**
 * Styles
 */
const useStyles = makeStyles(() =>
  createStyles({
    container: {
      paddingLeft: 3,
      paddingRight: 3
    },
    labelTime: {
      letterSpacing: 0.87,
      fontSize: 12,
      color: "#65747b"
    },
    resultTime: {
      letterSpacing: -0.35,
      fontSize: 12,
      color: "#65747b"
    },
    finish: {
      color: colorCAC.greenLight,
      fontWeight: 600
    }
  })
);

/**
 * TimeLabelsCard props
 */
interface ITimeLabelsCardProps {
  confirmedTime: number;
  estimatedTime: number;
  entity: "activity" | "module";
  approval?: boolean;
  isQuiz?: boolean;
  questionCount?: number | null;
  validatedQuestionCount?: number | null;
}

/** TimeLabelsCard */
const TimeLabelsCard = (props: ITimeLabelsCardProps) => {
  const classes = useStyles();
  const {
    confirmedTime,
    entity,
    estimatedTime,
    approval,
    isQuiz,
    questionCount,
    validatedQuestionCount
  } = props;

  return (
    <Grid container={true} item={true} className={classes.container}>
      <Grid container={true} item={true} justify="space-between">
        <Typography
          component="span"
          color="textSecondary"
          gutterBottom={false}
          noWrap={true}
          className={classes.labelTime}
        >
          {approval ? `Temps homologué réalisé` : `Temps de formation réalisé`}
        </Typography>
        <Typography
          component="span"
          gutterBottom={false}
          color="textSecondary"
          className={`${classes.resultTime} ${
            confirmedTime === estimatedTime ? classes.finish : ""
          }`}
        >
          {getSecondsFormatted(confirmedTime)}
        </Typography>
      </Grid>
      <Grid container={true} item={true}>
        <Grid container={true} item={true} justify="space-between">
          <Typography
            component="span"
            color="textSecondary"
            gutterBottom={false}
            noWrap={true}
            className={classes.labelTime}
          >
            {approval && entity === "module"
              ? `Temps homologué du module`
              : approval && entity === "activity"
              ? `Temps homologué de l'activité`
              : entity === "module"
              ? `Temps du module`
              : `Temps de l'activité`}
          </Typography>
          <Typography
            component="span"
            color="textSecondary"
            gutterBottom={false}
            className={classes.resultTime}
          >
            {getSecondsFormatted(estimatedTime)}
          </Typography>
          {isQuiz && (
            <Grid container={true} item={true}>
              <Grid container={true} item={true} justify="space-between">
                <Typography
                  component="span"
                  color="textSecondary"
                  gutterBottom={false}
                  noWrap={true}
                  className={classes.labelTime}
                >
                  Questions réalisées&nbsp;
                </Typography>
                <Typography
                  component="span"
                  color="textSecondary"
                  gutterBottom={false}
                  className={classes.resultTime}
                >
                  {validatedQuestionCount || 0}/{questionCount}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default React.memo(TimeLabelsCard);
