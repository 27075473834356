import { action, ActionType } from "typesafe-actions";
import { setItemLocalStorage } from "../../services/utility.service";
import { Module } from "../../models/module.model";

export const SELECTED_PATH = "[Module] SELECTED_PATH";

export const CURRENT_MODULE = "[CAC] CURRENT_MODULE";
export const CURRENT_MODULE_ACTIVITIES = "[CAC] CURRENT_MODULE_ACTIVITIES";

export const CURRENT_BLOC_ID = "[CAC] CURRENT_BLOC_ID";

export const setSelectedPath = (path: {
  blocId: string;
  themeId: string;
  activityId: string;
}) => {
  setItemLocalStorage(SELECTED_PATH, path);
  return action(SELECTED_PATH, path);
};

export const setCurrentModule = (module: Module) =>
  action(CURRENT_MODULE, module);

export const setCurrentBlocId = (id: string) => action(CURRENT_BLOC_ID, id);

const allActions = {
  setSelectedPath,
  setCurrentModule,
  setCurrentBlocId
};

export type BlocAction = ActionType<typeof allActions>;
