// React
import React, { memo } from "react";

// Lib
import { Helmet } from "react-helmet";

// Material
import {
  Box,
  Typography,
  Grid,
  Button,
  Dialog,
  Container
} from "@material-ui/core";

// Style
import { makeStyles, Theme } from "@material-ui/core/styles";

// Services
import { disconnectUser } from "../../services/user.service";

// Images
import logo from "../../assets/rf-e-learning-cac.png";

// Keycloak
import { useAuthentication } from "@dsk-lib/user";
import { CONST } from "../../config/constant";

const useStyles = makeStyles((theme: Theme) => ({
  unauthorizedDialog: {
    zIndex: "2001!important" as any
  },
  logo: {
    width: 200,
    [theme.breakpoints.down("xs")]: {
      width: "180px"
    }
  }
}));

/**
 * Unauthorized component
 */
const Unauthorized = () => {
  const { keycloak } = useAuthentication();
  /** Classes */
  const classes = useStyles();

  /**
   * Check offers
   */
  const checkOffers = () => {
    window.open(
      "https://boutique.grouperf.com/produit/rf-e-learning-cac",
      "_blank"
    );
    disconnectUser(keycloak);
  };

  /**
   * Redirect user on EL
   */
  const redirectOnEL = () => {
    window.open(`${CONST.RF_LEARNING_URL}`, "_blank");
  };

  return (
    <Dialog
      fullScreen={true}
      open={true}
      keepMounted={true}
      className={classes.unauthorizedDialog}
    >
      <Container maxWidth="md" style={{ padding: 0 }}>
        <Helmet defer={false}>
          <meta charSet="utf-8" />
          <title>RF e-Learning CAC : accès non autorisé</title>
        </Helmet>
        <Grid container={true} alignItems="center" justify="center">
          <Box m={2}>
            <img src={logo} className={classes.logo} alt="rf-elearning" />
          </Box>
        </Grid>
        <Typography align="center" paragraph={true}>
          Nous constatons que vous n'êtes pas encore abonné à RF e-Learning CAC
        </Typography>
        <Typography align="center" paragraph={true}>
          Nous vous incitons donc à découvrir nos offres commerciales qui
          répondront à vos besoins en cliquant sur le bouton ci-dessous.
        </Typography>
        <Typography align="center" paragraph={true}>
          Merci et à bientôt !
        </Typography>
        <Typography align="center" paragraph={true}>
          L'équipe formation de la Revue Fiduciaire
        </Typography>
        <Grid container={true} alignItems="center" justify="center">
          <Button
            color="primary"
            style={{ color: "white" }}
            variant="contained"
            onClick={() => checkOffers()}
          >
            Voir nos offres
          </Button>
        </Grid>
        <Grid container={true} alignItems="center" justify="center">
          <Box m={2}>
            <Typography align="center" variant="subtitle2">
              ou
            </Typography>
          </Box>
        </Grid>
        <Grid container={true} alignItems="center" justify="center">
          <Button
            color="primary"
            style={{ color: "white" }}
            variant="contained"
            onClick={() => redirectOnEL()}
          >
            Se connecter sur RF E-LEARNING
          </Button>
        </Grid>
        <Grid container={true} alignItems="center" justify="center">
          <Box m={2}>
            <Typography align="center" variant="subtitle2">
              ou
            </Typography>
          </Box>
        </Grid>
        <Grid container={true} alignItems="center" justify="center">
          <Button
            color="primary"
            style={{ color: "white" }}
            variant="contained"
            onClick={() => keycloak.logout()}
          >
            Retour au portail de connexion
          </Button>
        </Grid>
      </Container>
    </Dialog>
  );
};

export default memo(Unauthorized);
