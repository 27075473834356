import { action, ActionType } from "typesafe-actions";
import { SET_SURVEY_CHOICE } from "./types";

export const setSurveyChoice = (subscribed: boolean | null) => {
  return action(SET_SURVEY_CHOICE, subscribed);
};

const allActions = {
  setSurveyChoice: setSurveyChoice
};

export type surveyAction = ActionType<typeof allActions>;
