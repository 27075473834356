// React
import React from "react";

// Material
import { Button, Grid, Paper, Typography } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Modal from "@material-ui/core/Modal";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      zIndex: "2001!important" as any
    },
    modalContent: {
      padding: theme.spacing(5),
      minWidth: "40vw"
    }
  })
);

/**
 * ConfirmModalProps props
 */
interface IConfirmModalProps {
  handleClose: () => void;
  handleConfirm: () => void;
  open: boolean;
  title: string;
  content: string;
  // disable confirm button if needed
  confirmed?: boolean;
}

/**
 * ConfirmModal component
 */
const ConfirmModal = (props: IConfirmModalProps) => {
  /** Classes  */
  const classes = useStyles();

  const { handleClose, handleConfirm, open, title, content, confirmed } = props;

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition={true}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Fade in={open}>
        <React.Fragment>
          <Paper className={classes.modalContent}>
            <Grid container={true} spacing={3} direction="column">
              <Grid item={true}>
                <Typography variant="h5" component="h3">
                  {title}
                </Typography>
              </Grid>
              <Grid item={true}>
                <Typography component="div">
                  <div dangerouslySetInnerHTML={{ __html: content }} />
                </Typography>
              </Grid>
              <Grid
                item={true}
                container={true}
                spacing={3}
                direction="row-reverse"
              >
                <Grid item={true}>
                  <Button variant="contained" onClick={() => handleClose()}>
                    Annuler
                  </Button>
                </Grid>
                <Grid item={true}>
                  <Button
                    disabled={confirmed}
                    variant="contained"
                    color="primary"
                    style={{ color: "#FFF" }}
                    onClick={() => handleConfirm()}
                  >
                    Valider
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </React.Fragment>
      </Fade>
    </Modal>
  );
};

export default React.memo(ConfirmModal);
