import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";

export const getPdf = async (content: JSX.Element, filename: string) => {
  return pdf(content)
    .toBlob()
    .then(blob => saveAs(blob, filename));
};

export const setItemLocalStorage = (key: string, item: any) => {
  localStorage.setItem(key, JSON.stringify(item));
};

export const getItemLocalStorage = (key: string) => {
  return JSON.parse(localStorage.getItem(key) || "{}");
};
