import { createHttpLink } from "@apollo/client/core";

export async function getStandaloneApolloClient() {
  const { ApolloClient, InMemoryCache } = await import("@apollo/client");
  return new ApolloClient({
    ssrMode: false,
    link: createHttpLink({
      uri: process.env.REACT_APP_GRAPHQL_ENDPOINT
    }),
    cache: new InMemoryCache()
  });
}
