import { Reducer } from "redux";
import {
  SELECTED_PATH,
  CURRENT_MODULE,
  CURRENT_BLOC_ID,
  BlocAction
} from "./actions";
import { getItemLocalStorage } from "../../services/utility.service";
import { Module } from "../../models/module.model";

export interface IModule {
  selectedPath: { blocId: string; themeId: string; activityId: string };
  currentModule: Module | null;
  currentBlocId: string;
}

const initialState: IModule = {
  selectedPath: getItemLocalStorage(SELECTED_PATH) || {
    blocId: "",
    themeId: "",
    activityId: ""
  },
  currentModule: null,
  currentBlocId: ""
};

const reducer: Reducer<any, BlocAction> = (state = initialState, action) => {
  switch (action.type) {
    case SELECTED_PATH:
      return {
        ...state,
        selectedPath: action.payload
      };
    case CURRENT_MODULE:
      return {
        ...state,
        currentModule: action.payload
      };
    case CURRENT_BLOC_ID:
      return {
        ...state,
        currentBlocId: action.payload
      };
    default:
      return state;
  }
};

export { reducer as ModuleReducer };
