// React
import { CSSProperties } from "react";

// Images
import waveEnvLegalPro from "../assets/green-wave.svg";
import iconDossierMois from "../assets/ic-dossier-mois.svg";
import iconEnvLegalPro from "../assets/ic-env-legal-pro.svg";
import iconPratiqueComptable from "../assets/ic-pratique-comptable.svg";
import waveDossierMois from "../assets/purple-wave.svg";
import wavePratiqueComptable from "../assets/yellow-wave.svg";

import landingQuiz1 from "../assets/landing-starter-quiz-1.svg";
import landingQuiz2 from "../assets/landing-starter-quiz-2.svg";
import landingQuiz3 from "../assets/landing-starter-quiz-3.svg";
import { BlocSlug } from "../models/module.model";

/**
 * Get bloc's icon
 * @param blocName
 */
export const getBlocIcon = (slug: string): string => {
  switch (slug) {
    case BlocSlug.PratiqueComptable:
      return iconPratiqueComptable;
    case BlocSlug.EnvironnementLegal:
      return iconEnvLegalPro;
    default:
      return iconDossierMois;
  }
};

/**
 * Get bloc's wave
 * @param slug
 */
export const getBlocWave = (slug: string): string => {
  switch (slug) {
    case BlocSlug.PratiqueComptable:
      return wavePratiqueComptable;
    case BlocSlug.EnvironnementLegal:
      return waveEnvLegalPro;
    default:
      return waveDossierMois;
  }
};

/** Get indicator's background color */
export const getIndicatorColor = (slug: string): CSSProperties => {
  let backgroundColor = "#000";
  switch (slug) {
    case BlocSlug.PratiqueComptable:
      backgroundColor = "#f9cc00";
      break;
    case BlocSlug.EnvironnementLegal:
      backgroundColor = "#006e49";
      break;
    default:
      backgroundColor = "#82368c";
      break;
  }

  return { backgroundColor };
};

/** Get starter quiz image */
export const getStarterQuizImage = (slug: string): string => {
  switch (slug) {
    case BlocSlug.PratiqueComptable:
      return landingQuiz1;
    case BlocSlug.EnvironnementLegal:
      return landingQuiz2;
    default:
      return landingQuiz3;
  }
};

/**
 * Get title from bloc slug
 */
export const getBlocTitle = (slug: string): string => {
  switch (slug) {
    case BlocSlug.PratiqueComptable:
      return "PRATIQUE COMPTABLE";
    case BlocSlug.EnvironnementLegal:
      return "ENVIRONNEMENT LÉGAL ET PROFESSIONNEL";
    default:
      return "DOSSIER DU MOIS";
  }
};
