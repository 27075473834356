import { CONST } from "../config/constant";
import {
  BookmarkStatus,
  BookmarkType,
  LessonDetailsFragment,
  QuizDetailsFragment,
  QuizProgress
} from "../graphql";
import { Activity, Lesson, Quiz, Type } from "../models/activity.model";
import { mapModule } from "./module.service";

export const mapActivity = (
  activity: QuizDetailsFragment | LessonDetailsFragment
): Activity => {
  let isStarted = false;
  let isDone = false;
  let confirmedTime = 0;
  let progress = 0;
  let validatedQuestionCount = 0;

  if (activity.elapsedTime) {
    if (activity.elapsedTime?.duration && activity?.estimatedTime) {
      confirmedTime =
        activity?.estimatedTime > activity.elapsedTime?.duration
          ? activity.elapsedTime?.duration
          : activity.estimatedTime;
    }
    progress = activity.estimatedTime
      ? Math.floor((confirmedTime / activity.estimatedTime) * 100)
      : 0;
    isDone = progress === 100 ? true : false;
    isStarted = confirmedTime > 0;
  }

  if (activity.__typename === "Quiz") {
    const lastBookmark = getLastBookmark(activity.bookmark ?? []);

    if (lastBookmark) {
      isStarted = true;

      if (
        lastBookmark?.status === BookmarkStatus.Done &&
        !lastBookmark.current_question_id
      ) {
        progress = 100;
        validatedQuestionCount = activity.questionCount;
      } else if (
        lastBookmark?.current_question_id &&
        activity.exerciseListData
      ) {
        const index = activity.exerciseListData.findIndex(
          e => e?.learningId === lastBookmark.current_question_id
        );

        if (index >= 0) {
          validatedQuestionCount = index + 1;
          progress = Math.floor(
            (validatedQuestionCount / activity.questionCount) * 100
          );
        }
      }
    }
  }

  return {
    ...activity,
    confirmedTime,
    progressbar: progress,
    isStarted,
    isDone,
    validatedQuestionCount,
    type: activity?.tags?.[0] as Type,
    module:
      activity.parentCategories?.length &&
      activity.parentCategories[0]?.parent?.parent
        ? mapModule(activity.parentCategories[0].parent?.parent)
        : undefined
  };
};

/**
 * Get activity's youtube ID from youtube URL
 * @param activity
 * @returns
 */
export const getYoutubeId = (activity: Activity): string => {
  if (activity.assetLinks) {
    const asset = activity.assetLinks.find(
      a => a?.type === "external-attachment"
    );
    if (asset?.media?.url) {
      const regexMatch = asset.media.url.match(
        /(https?:\/\/)?(www.)?youtu(be)?\.((com)|(be)){1}\/((embed\/)|(watch\?v=))?([A-Za-z0-9-_]+)(\?.*)?/i
      );
      if (regexMatch && regexMatch.length >= 9) {
        return regexMatch[10];
      }
    }
  }
  return "";
};

export const isQuiz = (activity: Activity): activity is Quiz => {
  return activity?.__typename === "Quiz";
};

export const isLesson = (activity: Activity): activity is Lesson => {
  return activity?.__typename === "Lesson";
};

/**
 * Get most recent progress for quiz or quiz question
 * @param bookmarks List of progresses
 * @param checkStarter Check starter quiz (mock exam) if true, standard if false
 * @param currentQuestionId Optionnal current question ID
 * @returns
 */
export const getLastBookmark = (
  bookmarks?: (QuizProgress | null)[],
  checkStarter: boolean = false,
  currentQuestionId?: number | null
): QuizProgress | null | undefined => {
  const bookmarksFiltered = bookmarks?.filter(b => {
    return (
      ((checkStarter && b?.type === BookmarkType.MockExam) ||
        (!checkStarter && b?.type === BookmarkType.Standard)) &&
      (!currentQuestionId ||
        (currentQuestionId && b.current_question_id === currentQuestionId))
    );
  });

  return bookmarksFiltered && bookmarksFiltered.length > 0
    ? bookmarksFiltered[0]
    : undefined;
};

/**
 * Format HTML content to display
 * @param lesson
 * @returns
 */
export const getContentList = async (lesson: Lesson): Promise<string[]> => {
  if (lesson.type === Type.FicheInfo) {
    let contentList: string[] = [];
    const contentInitial = lesson.html;

    // Split content depending on <div class="item">
    let contentSplit = contentInitial
      .split(/<[\s]*div[\s]*class="item"[\s]*>/i)
      .filter(c => !!c);

    // Make an array of div
    if (contentSplit.length > 1) {
      contentList = contentSplit.map(c => '<div class="item">' + c);
    } else {
      contentList = [contentInitial];
    }

    // Fetch media content
    const mediaLinks = lesson.assetLinks ?? [];
    for (const m of mediaLinks) {
      if (m?.media?.type === "okulus" && m.type === "external-attachment") {
        const mediaContent = await fetch(
          `${CONST.OKULUS_URL}/${m.media.okulusId}`
        )
          .then(response => response.text())
          .catch(e => console.error(e));

        if (mediaContent && mediaContent.match(/item/i)) {
          contentList.push(mediaContent);
        }
      }
    }

    return contentList;
  }

  return [];
};
