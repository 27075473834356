import { LearningTypes } from "@dsk-lib/user";
import React from "react";
import io, { Socket } from "socket.io-client";

interface TimeProps {
  url: string;
  isExpired: boolean;
  learningProduct: string;
  userID?: string;
  learningID?: number;
  learningType?: LearningTypes;
  limit?: number;
  categoryId?: string;
  activityId?: string;
}

let socket: typeof Socket | undefined;

export const useTime = ({
  url,
  userID,
  learningProduct,
  learningType,
  limit,
  categoryId,
  activityId
}: TimeProps): null => {
  React.useEffect(() => {
    if (userID !== undefined && learningProduct !== undefined) {
      if (socket != null) {
        socket.close();
      }
      socket = io(url);
      console.info("WebSocket Client Connected");
      socket.emit("start", {
        userID,
        learningProduct,
        learningType,
        limit,
        categoryId,
        activityId
      });
      return () => {
        console.info("WebSocket Client has disconnected");
        socket?.close();
      };
    }
    console.warn("[TIME] No userId id or learningProduct set");
    return () => {
      console.info("WebSocket Client has disconnected");
      socket?.close();
    };
  }, [userID, learningProduct, learningType, url, categoryId, activityId]);

  return null;
};
