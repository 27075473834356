import { makeStyles, Typography } from "@material-ui/core";
import React from "react";

/**
 * Use styles
 */
const useStyles = makeStyles(theme => ({
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: "0 8px",
    ...theme.mixins.toolbar,
    justifyContent: "flex-end"
  }
}));

/**
 * NoMatch component
 */
const NoMatch: React.FC = () => {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.drawerHeader} />
      <Typography align={"center"}>
        Désolé mais cette page n'existe pas
      </Typography>
    </div>
  );
};

export default NoMatch;
