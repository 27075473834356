// React
import React from "react";

// Material
import {
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography
} from "@material-ui/core";
import MuiExpansionPanel from "@material-ui/core/ExpansionPanel";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    expansionPanel: {
      minWidth: "100%",
      color: "#19110b",
      marginBottom: theme.spacing(2)
    },
    heading: {
      fontSize: 17,
      fontWeight: 600
    },
    panelContent: {
      fontSize: 15,
      wordBreak: "break-word"
    }
  })
);
const ExpansionPanel = withStyles({
  root: {
    backgroundColor: "transparent",
    boxShadow: "none",
    "&:before": {
      display: "none"
    },
    "&$expanded": {
      margin: "auto",
      marginBottom: 20,
      backgroundColor: "white"
    }
  },
  expanded: {}
})(MuiExpansionPanel);

/**
 * FaqExpansionPanelProps props
 */
interface IFaqExpansionPanelProps {
  panelId: string;
  expanded: boolean;
  title: string;
  content: string;
  expandedPanel(
    panelId: string
  ): (event: React.ChangeEvent<{}>, newExpanded: boolean) => void;
}

/**
 * FaqExpansionPanel component
 */
const FaqExpansionPanel = (props: IFaqExpansionPanelProps) => {
  /** Classes  */
  const classes = useStyles();

  const { expandedPanel, panelId, expanded, title, content } = props;

  return (
    <ExpansionPanel
      className={classes.expansionPanel}
      square={true}
      expanded={expanded}
      onChange={expandedPanel(!expanded ? panelId : "")}
    >
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`expand-${panelId}`}
        id={`expand-${panelId}`}
      >
        <Typography className={classes.heading}>{title}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <div
          className={classes.panelContent}
          dangerouslySetInnerHTML={{
            __html: content
          }}
        />
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default React.memo(FaqExpansionPanel);
