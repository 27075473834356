// React
import React from "react";

// Alert
import "./Alert.scss";

/**
 * AlertProps props
 */
interface IAlertProps {
  type: "error" | "success" | "warning" | "info";
  children?: string | null;
}

/**
 * Alert component
 */
const Alert = (props: IAlertProps) => {
  /** Props */
  const { type, children } = props;

  return (
    <React.Fragment>
      {children && <div className={`alert alert-${type}`}>{children}</div>}
    </React.Fragment>
  );
};

export default React.memo(Alert);
