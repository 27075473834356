// React
import React, { useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { RouteComponentProps } from "react-router";
import { NavLink, NavLinkProps, withRouter } from "react-router-dom";

// Material
import {
  Divider,
  Grid,
  Hidden,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Typography
} from "@material-ui/core";

// Icons
import ListItemIcon from "@material-ui/core/ListItemIcon";

// Style
import { makeStyles } from "@material-ui/core/styles";

// Images
import iconAccueil from "../../assets/ic-accueil.svg";
import iconAttestation from "../../assets/ic-attestations.svg";
import iconModuleMois from "../../assets/ic-module-mois.svg";
import iconProfil from "../../assets/ic-profil.svg";
import iconTousModules from "../../assets/ic-tous-modules.svg";
import logoRF from "../../assets/Logo_RF_Quadri.png";

// Services
import { disconnectUser } from "../../services/user.service";

// Helpers
import { titleCase } from "../../helpers/text-formatter.helper";
import { getSecondsFormatted } from "../../helpers/date-fomatter.helper";

// Const
import { CONST, ROUTES } from "../../config/constant";

// Keycloak
import { useAuthentication } from "@dsk-lib/user";
import { useSelector } from "react-redux";
import { StoreState } from "../../store";

/**
 * Use style
 */
const useStyles = makeStyles(theme => ({
  toolbar: theme.mixins.toolbar,
  homeSelected: {
    backgroundColor: "rgba(127, 188, 77, 0.2) !important",
    fontWeight: 600
  },
  monthModuleSelected: {
    backgroundColor: "rgba(0, 110, 73, 0.2) !important",
    fontWeight: 600
  },
  allModulesSelected: {
    backgroundColor: "rgba(243, 146, 0, 0.2) !important",
    fontWeight: 600
  },
  profilSelected: {
    backgroundColor: "rgba(0, 68, 44, 0.2) !important",
    fontWeight: 600
  },
  myCertificatesSelected: {
    backgroundColor: "rgba(249, 204, 0, 0.2) !important",
    fontWeight: 600
  },
  smallItemSelected: {
    backgroundColor: "rgba(0, 116, 80, 0.2) !important",
    color: "#037450",
    fontWeight: 600
  },
  textMenu: {
    fontSize: "1em"
  },
  textSubheader: {
    fontWeight: 600,
    color: "#1d1d1b"
  },
  textSubheaderConfirmedTimeElapsed: {
    fontSize: "0.95em",
    color: "#1d1d1b"
  },
  itemMenu: {
    paddingTop: 2,
    paddingBottom: 2
  },
  nav: {
    paddingTop: 0
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
  image: {
    width: 130
  },
  logo: {
    width: 150
  },
  smallTextItem: {
    opacity: 0.7,
    fontSize: "0.9em",
    paddingLeft: 16
  },
  textDeconnection: {
    color: "#e51049"
  },
  adminLabel: {
    paddingLeft: 15
  }
}));

/**
 * Menu Props
 */
interface IMenuProps {
  username?: string;
  isSuperAdmin?: boolean;
  onClose: () => void;
}

/**
 * Menu Component
 */
const Menu = (props: RouteComponentProps & IMenuProps) => {
  const { keycloak } = useAuthentication();
  const {
    username,
    isSuperAdmin,
    onClose,
    location: { pathname }
  } = props;

  /** Classes */
  const classes = useStyles();
  /** Wrapped nav link */
  const WrappedNavLink = React.forwardRef((props: NavLinkProps, ref: any) => (
    <NavLink {...props} innerRef={ref} />
  ));
  /** State item selected */
  const [itemSelected, setItemSelected] = useState(0);
  /** Current year time */
  const currentYearConfirmedTime = useSelector(
    (state: StoreState) => state.currentYearTime
  );

  /**
   * HandleClickItem
   */
  const handleClickItem = (item?: number) => {
    if (item) {
      setItemSelected(item);
    }
    if (username) {
      onClose();
    }
  };

  /**
   * Use effect
   */
  useEffect(() => {
    const pathnames = pathname.split("/").filter((item: string) => item);
    const found = ROUTES.find(
      (element: { ID: number; PATH: string }) =>
        element.PATH === "/" + pathnames[0]
    );
    if (found) {
      setItemSelected(found.ID);
    }
  }, [pathname]);

  return (
    <>
      <div className={classes.toolbar} />
      <Scrollbars autoHide={true}>
        <List component="nav" className={classes.nav}>
          <ListSubheader
            component="div"
            className={classes.textSubheaderConfirmedTimeElapsed}
            disableSticky={true}
          >
            <React.Fragment>
              Total formation réalisée en {CONST.CURRENT_YEAR} :{" "}
              <b>{getSecondsFormatted(currentYearConfirmedTime)}</b>
            </React.Fragment>
          </ListSubheader>
          <ListItem
            component={WrappedNavLink}
            to={`${CONST.ROUTES.HOME}`}
            button={true}
            onClick={() => handleClickItem(0)}
            className={`${itemSelected === 0 ? classes.homeSelected : null}`}
          >
            <ListItemIcon>
              <img src={iconAccueil} alt="icon-person" />
            </ListItemIcon>
            <ListItemText
              inset={false}
              primary={"Accueil"}
              className={`${classes.textMenu}`}
              disableTypography={true}
            />
          </ListItem>
          <ListItem
            component={WrappedNavLink}
            to={`${CONST.ROUTES.MONTH_MODULE}`}
            button={true}
            onClick={() => handleClickItem(1)}
            className={`${
              itemSelected === 1 ? classes.monthModuleSelected : null
            }`}
          >
            <ListItemIcon>
              <img src={iconModuleMois} alt="icon-person" />
            </ListItemIcon>
            <ListItemText
              inset={false}
              primary={"Module du mois"}
              className={`${classes.textMenu}`}
              disableTypography={true}
            />
          </ListItem>
          <ListItem
            component={WrappedNavLink}
            to={`${CONST.ROUTES.MODULES}`}
            button={true}
            onClick={() => handleClickItem(2)}
            className={`${
              itemSelected === 2 ? classes.allModulesSelected : null
            }`}
          >
            <ListItemIcon>
              <img src={iconTousModules} alt="icon-person" />
            </ListItemIcon>
            <ListItemText
              inset={false}
              primary={"Tous mes modules"}
              className={`${classes.textMenu}`}
              disableTypography={true}
            />
          </ListItem>

          <ListSubheader
            component="div"
            className={classes.textSubheader}
            disableSticky={true}
          >
            MON TABLEAU DE BORD
          </ListSubheader>
          <Hidden smUp={true}>
            {username ? (
              <ListItem button={false} className={classes.itemMenu}>
                <ListItemText primary={titleCase(username!)} />
              </ListItem>
            ) : null}
            {isSuperAdmin && (
              <Typography className={classes.adminLabel} variant={"caption"}>
                Super Admin
              </Typography>
            )}
          </Hidden>
          <ListItem
            component={WrappedNavLink}
            to={`${CONST.ROUTES.DASHBOARD}`}
            button={true}
            onClick={() => handleClickItem(3)}
            className={`${itemSelected === 3 ? classes.profilSelected : null}`}
          >
            <ListItemIcon>
              <img src={iconProfil} alt="icon-person" />
            </ListItemIcon>
            <ListItemText
              inset={false}
              primary={"Mon profil"}
              className={`${classes.textMenu}`}
              disableTypography={true}
            />
          </ListItem>
          <ListItem
            component={WrappedNavLink}
            to={`${CONST.ROUTES.CERTIFICATE}`}
            button={true}
            onClick={() => handleClickItem(4)}
            className={`${
              itemSelected === 4 ? classes.myCertificatesSelected : null
            }`}
          >
            <ListItemIcon>
              <img src={iconAttestation} alt="icon-person" />
            </ListItemIcon>
            <ListItemText
              inset={false}
              primary={"Mes attestations"}
              className={`${classes.textMenu}`}
              disableTypography={true}
            />
          </ListItem>
          <Hidden smUp={true}>
            <Divider component="li" />
            <ListItem button={true}>
              <ListItemText
                disableTypography={true}
                primary={"Déconnexion"}
                onClick={() => disconnectUser(keycloak)}
                className={`${classes.smallTextItem} ${classes.textDeconnection}`}
              />
            </ListItem>
            <Divider component="li" />
          </Hidden>
          <ListSubheader
            component="div"
            className={classes.textSubheader}
            disableSticky={true}
          >
            EN SAVOIR PLUS
          </ListSubheader>
          <ListItem
            className={classes.itemMenu}
            button
            component="a"
            rel="noopener"
            target="_blank"
            href={CONST.PAGE_RF_COMPTABLE}
          >
            <ListItemText
              disableTypography={true}
              className={classes.smallTextItem}
              primary="Accès à RF Comptable"
            />
          </ListItem>
          <ListItem
            component={WrappedNavLink}
            to={`${CONST.ROUTES.CONTACT}`}
            button={true}
            onClick={() => handleClickItem(5)}
            className={`${classes.itemMenu} ${
              itemSelected === 5 ? classes.smallItemSelected : null
            }`}
          >
            <ListItemText
              disableTypography={true}
              primary={"Contactez-nous"}
              className={classes.smallTextItem}
            />
          </ListItem>
          <ListItem
            component={WrappedNavLink}
            to={`${CONST.ROUTES.FAQ}`}
            button={true}
            onClick={() => handleClickItem(6)}
            className={`${classes.itemMenu} ${
              itemSelected === 6 ? classes.smallItemSelected : null
            }`}
          >
            <ListItemText
              disableTypography={true}
              primary={"FAQ"}
              className={classes.smallTextItem}
            />
          </ListItem>
          <ListItem
            component={WrappedNavLink}
            to={`${CONST.ROUTES.MENTIONS}`}
            button={true}
            onClick={() => handleClickItem(7)}
            className={`${classes.itemMenu} ${
              itemSelected === 7 ? classes.smallItemSelected : null
            }`}
          >
            <ListItemText
              disableTypography={true}
              primary={"Mentions légales"}
              className={classes.smallTextItem}
            />
          </ListItem>
        </List>
        <Grid
          container={true}
          alignItems="center"
          justify="space-between"
          direction="column"
        >
          <Grid item={true}>
            <img
              src={logoRF}
              alt="revue fiduciaire"
              className={classes.image}
            />
          </Grid>
          <Grid item={true}>
            <Typography variant="body2" align={"center"}>
              Tous droits réservés
            </Typography>
            <Typography variant="body2" align={"center"}>
              Revue Fiduciaire © {CONST.CURRENT_YEAR}
            </Typography>
          </Grid>
        </Grid>
      </Scrollbars>
    </>
  );
};

export default withRouter(React.memo(Menu));
