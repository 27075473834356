// React
import React from "react";

// Material
import { Box, Card, CardContent, Grid, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

// Models
import { Module } from "../../models/module.model";

// Styles
import { colorCAC } from "../../styles/color";

// Images
import iconInfoExpert from "../../assets/ic-info-expert.svg";

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      backgroundColor: colorCAC.grayCard,
      padding: theme.spacing(0.5, 0.5),
      [theme.breakpoints.up("md")]: {
        borderLeft: "4px outset",
        borderLeftColor: colorCAC.orange
      },
      [theme.breakpoints.down("sm")]: {
        borderTop: "4px outset",
        borderTopColor: colorCAC.orange
      }
    },
    title: {
      color: "#FFFFFF",
      backgroundColor: colorCAC.orange,
      paddingLeft: 3,
      paddingRight: 3
    }
  })
);

/**
 * MonthlyCardInfoExpert props
 */
interface ILastModuleInfoExpertCard {
  module: Module;
}

/**
 * MonthlyCardInfoExpert component
 */
const MonthlyCardInfoExpert = ({ module }: ILastModuleInfoExpertCard) => {
  /** Classes  */
  const classes = useStyles();
  return (
    <React.Fragment>
      <Card className={classes.card}>
        <CardContent>
          <Grid container={true}>
            <Grid item={true}>
              <Box
                marginBottom={1}
                marginTop={1}
                fontWeight="600"
                color="textPrimary"
                fontSize="h6.fontSize"
              >
                <Grid container={true} spacing={1} alignItems={"center"}>
                  <Grid item={true}>
                    <img src={iconInfoExpert} alt="info expert" />
                  </Grid>
                  <Grid item={true}>L'INFO DE L'EXPERT</Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item={true} xs={12}>
              {module.expertOpinion ? (
                <Typography
                  variant="subtitle2"
                  color="textPrimary"
                  gutterBottom={true}
                >
                  <div
                    dangerouslySetInnerHTML={{ __html: module.expertOpinion }}
                  />
                </Typography>
              ) : null}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default React.memo(MonthlyCardInfoExpert);
