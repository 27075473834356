import { Faq } from "../models/faq.model";

/**
 * Faq data
 */
export const provideFaqData = () => {
  const faqList: Faq[] = [
    {
      title: "J'ai perdu mon mot de passe",
      content:
        "Il suffit de renseigner votre identifiant dans le champ prévu à cet effet sur le formulaire accessible à cette adresse <a href='https://moncompte.grouperf.com/mot_de_passe_oublie.html' target='_blank'>https://moncompte.grouperf.com/mot_de_passe_oublie.html</a> ou en cliquant sur le lien “mot de passe oublié” depuis le formulaire de connexion.<br/>Vous recevrez ensuite un e-mail avec une procédure vous expliquant comment réinitialiser votre mot de passe."
    },
    {
      title: "J'ai perdu ou je ne me souviens plus de mon identifiant",
      content:
        "Il suffit de contacter par courriel <a href='mailto:src@grouperf.com'>src@grouperf.com</a> qui vous renverra votre identifiant en indiquant bien vos nom, prénom, raison sociale ainsi que votre numéro de client."
    },
    {
      title:
        "Le site ne s'ouvre pas, la plateforme se fige, des messages d'erreur apparaissent…",
      content:
        "Contactez-nous par e-mail à <a href='mailto:src@grouperf.com'>src@grouperf.com</a> et joignez les détails du dysfonctionnement (nom du stagiaire, nom du navigateur internet, nom du cours, étape du cours, date et heure, message d’erreur le cas échéant, et toute information qui vous semble utile ...)."
    },
    {
      title: "Quand puis-je télécharger mon attestation ?",
      content:
        "Chaque module délivre 1 attestation et elle ne s’affiche qu’à partir du moment où un module est complété à 100 % (réalisation de toutes les activités des blocs “Pratique comptable”, “Environnement légal et professionnel” et “Dossier du mois”).<br/>Téléchargez-la à tout moment dans votre rubrique “Mes attestations”.<br/>Un e-mail automatique vous sera également envoyé pour vous prévenir de la disponibilité de votre attestation."
    },
    {
      title:
        "À quoi la date qui s’affiche à l’intérieur de mon attestation correspond-elle ?",
      content:
        "La date qui s’affiche pour affirmer que vous avez bien réalisé la totalité du module sur chaque attestation correspond à la date à laquelle vous avez terminé 100 % des activités du module."
    },
    {
      title:
        " Combien de temps un module est-il compté dans mon temps de formation ?",
      content:
        "Les modules ont une durée de validité de 12 mois.<br/>Passé cette date, vous pourrez toujours avoir accès aux activités de ces modules mais le temps passé sur les activités ne sera plus compté dans votre temps de formation."
    },
    {
      title: "Je souhaiterais recevoir un support de formation papier",
      content:
        "Les formations e-Learning ne font pas l'objet de l'envoi d'une documentation papier. La Revue RF Comptable comprise dans votre abonnement est votre ressource documentaire associée."
    },
    {
      title:
        "J’ai besoin d’une convention de formation professionnelle continue",
      content:
        "Contactez-nous par e-mail à <a href='mailto:src@grouperf.com'>src@grouperf.com</a> et nous nous ferons un plaisir de vous envoyer en retour ce document à transmettre à votre organisme de financement."
    },
    {
      title: "Combien de temps sont stockés mes modules ?",
      content:
        "Vous avez accès à jusqu’à 3 ans d’archives des anciens modules. Durant cette période, ils restent consultables ainsi que les activités qui les composent (voir également point 6 ci-avant).<br/>Il en va de même pour les attestations qui sont accessibles jusqu’à 3 ans."
    },
    {
      title:
        "Je souhaite avoir accès à un ou plusieurs modules auxquels mon abonnement ne me donne pas droit",
      content:
        "Vous avez une interruption dans votre abonnement et souhaitez néanmoins accéder à un ancien module ? c’est possible. <br/>Il suffit de cliquer sur le bouton “Demander l’accès au module”.<br/>Une fois votre confirmation reçue, nous vous contacterons pour les modalités et dès acceptation de votre règlement, vous aurez accès à ce ou ces modules en vous connectant au site."
    }
  ];

  return {
    faqList
  };
};
