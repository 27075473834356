import { Reducer } from "redux";
import { surveyAction } from "./actions";
import { SET_SURVEY_CHOICE } from "./types";

const reducer: Reducer<boolean | null, surveyAction> = (
  state = null,
  action
) => {
  switch (action.type) {
    case SET_SURVEY_CHOICE:
      return action.payload;
    default:
      return state;
  }
};

export { reducer as surveyReducer };
