/**
 * Constante
 */
export const CONST_LOADER = {
  MODULE_MONTH: `
    <rect x="0" y="0" rx="3" ry="3" width="152" height="12"></rect> 
    <rect x="161" y="0" rx="3" ry="3" width="81" height="12"></rect>
  `,
  MODULE_TABS: `
    <rect x="0" y="17" rx="3" ry="3" width="209" height="83"></rect>
    <rect x="266" y="17" rx="3" ry="3" width="209" height="83"></rect>
    <rect x="535" y="17" rx="3" ry="3" width="209" height="83"></rect>
    <rect x="0" y="118" rx="3" ry="3" width="744" height="10"></rect>
  `,
  MODULE_CONTENT: `
    <rect x="0" y="0" rx="3" ry="3" width="744" height="94"></rect> 
    <rect x="62" y="105" rx="3" ry="3" width="682" height="148"></rect>
  `,
  MONTH_MODULE_MOBILE: `
    <rect x="0" y="8" rx="3" ry="3" width="225" height="30"></rect>
    <rect x="260" y="8" rx="3" ry="3" width="60" height="30"></rect>
    <rect x="0" y="60" rx="3" ry="3" width="999" height="70"></rect>
    <rect x="0" y="160" rx="3" ry="3" width="999" height="220"></rect>
    <rect x="0" y="410" rx="3" ry="3" width="999" height="220"></rect>
    <rect x="0" y="660" rx="3" ry="3" width="999" height="220"></rect>
  `,
  ACTIVITY_LESSON_OR_QUIZZ: `
    <rect x="10" y="5" rx="10" ry="10" width="150" height="72"></rect>
    <rect x="200" y="5" rx="10" ry="10" width="800" height="72"></rect>
    <rect x="1050" y="5" rx="10" ry="10" width="100" height="72"></rect>
    <rect x="200" y="100" rx="10" ry="10" width="800" height="600"></rect>
  `,
  QUIZZ_BILAN: `
  <rect x="0" y="0" rx="10" ry="10" width="100%" height="600"></rect>
`
};
