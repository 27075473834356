import { combineReducers } from "redux";
import { userReducer } from "./user/reducer";
import { IModule, ModuleReducer } from "./Module/reducer";
import { errorHandlerReducer, IErrorHandler } from "./errorHandler/reducer";
import { surveyReducer } from "./survey/reducer";
import { currentYearTimeReducer } from "./currentYearTime/reducer";

// Model
import { User } from "../models/user.model";

export interface StoreState {
  user: User | null;
  currentYearTime: number;
  moduleCac: IModule;
  errorHandler: IErrorHandler;
  surveyHandler: boolean | null;
}

export const reducers = combineReducers<StoreState>({
  user: userReducer,
  currentYearTime: currentYearTimeReducer,
  moduleCac: ModuleReducer,
  errorHandler: errorHandlerReducer,
  surveyHandler: surveyReducer
});
