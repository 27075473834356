// React
import React from "react";

// Material
import { Fab, Grid } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

// Icons
import { ArrowBack, ArrowForward } from "@material-ui/icons";

// Colors
import { colorCAC } from "../../styles/color";
import NextActivityBtn from "../NextActivity/NextActivityBtn";
import { ActivityPath } from "../../models/activity.model";

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pagerContainer: {
      width: 960,
      maxWidth: "100%",
      height: "100%",
      marginLeft: "auto",
      marginRight: "auto",
      [theme.breakpoints.down("sm")]: {}
    },
    pagerWrapper: {
      zIndex: 1,
      position: "fixed",
      padding: "0 29px",
      bottom: 10,
      fontSize: 14,
      lineHeight: 1.86,
      letterSpacing: ".3px",
      color: colorCAC.black,
      fontWeight: 400,
      marginTop: ".67em",
      marginBottom: ".67em"
    },
    pageProgressContainer: {
      bottom: 0,
      position: "absolute",
      width: 250,
      height: 6
    },
    pageProgressBarBg: {
      width: "100%",
      height: "100%",
      borderRadius: 20,
      overflow: "hidden",
      backgroundColor: "#ebedee"
    },
    pageProgressBarLine: {
      height: "100%",
      backgroundColor: colorCAC.greenRF
    },
    pageCounterContainer: {
      position: "absolute",
      bottom: 10,
      width: 250
    },
    pagerNavigation: {
      transform: "translateY(-50%)"
    },
    fab: {
      margin: theme.spacing(1),
      color: "#FFFFFF"
    }
  })
);

/**
 * ActivityPagerProps props
 */
interface IActivityPagerProps {
  page: number;
  totalPages: number;
  pagePercentage: number;
  pageNavigation(page: number): void;
  nextActivity?: ActivityPath;
}

/**
 * ActivityPager component
 */
const ActivityPager = (props: IActivityPagerProps) => {
  /** Classes  */
  const classes = useStyles();
  /** Props */
  const {
    pageNavigation,
    page,
    totalPages,
    pagePercentage,
    nextActivity
  } = props;

  return (
    <div className={classes.pagerContainer}>
      <div className={classes.pagerWrapper}>
        <div className={classes.pageProgressContainer}>
          <div className={classes.pageProgressBarBg}>
            <div
              className={classes.pageProgressBarLine}
              style={{ width: `${pagePercentage}%` }}
            />
          </div>
        </div>
        <div className={classes.pageCounterContainer}>
          Page&nbsp;
          <span>
            {page}/{totalPages}
          </span>
        </div>
      </div>

      <Grid item={true} xs={true} className={classes.pagerNavigation}>
        <Grid container={true} justify="flex-end" alignItems="center">
          <Fab
            color="primary"
            aria-label="back"
            className={classes.fab}
            onClick={() => pageNavigation(page - 1)}
            disabled={page === 1}
          >
            <ArrowBack />
          </Fab>
          {page !== totalPages ? (
            <Fab
              color="primary"
              aria-label="add"
              className={classes.fab}
              onClick={() => pageNavigation(page + 1)}
            >
              <ArrowForward />
            </Fab>
          ) : (
            <NextActivityBtn nextActivity={nextActivity} />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default React.memo(ActivityPager);
