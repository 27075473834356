import { LessonDetailsFragment, QuizDetailsFragment } from "../graphql";
import { Module } from "./module.model";

/**
 * Activity mapped members
 */
export interface ActivityMappedMembers {
  confirmedTime: number;
  progressbar: number;
  isStarted: boolean;
  isDone: boolean;
  validatedQuestionCount: number;
  type?: Type;
  module?: Module;
}

export type Quiz = QuizDetailsFragment & ActivityMappedMembers;
export type Lesson = LessonDetailsFragment &
  ActivityMappedMembers & { contentList?: string[] };

export type Activity = Quiz | Lesson;

export enum ActivityType {
  Quiz = "quiz",
  Lesson = "lesson"
}

export interface ActivityPath {
  _id: string;
  type: ActivityType;
  blocId: string;
  themeId: string;
}

export enum Type {
  FicheInfo = "FICHE_INFO",
  Video = "VIDEO",
  Annonce = "ANNONCE",
  QuizThematique = "QUIZ_THEMATIQUE",
  QuizFinal = "QUIZ_FINAL",
  Categorisation = "CATEGORISATION",
  Analyse = "ANALYSE"
}
