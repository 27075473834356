export function isIE(): boolean {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf("MSIE ");
  if (msie > 0 || isIE11()) {
    // IE 10 or older => return version number
    return true;
  }
  return false;
}

function isIE11(): boolean {
  return !!navigator.userAgent.match(/Trident.*rv[ :]*11\./);
}

export function isEdge(): boolean {
  return window.navigator.userAgent.indexOf("Edge") > -1;
}

/**
 * this function it's use for disable the scrolling
 */
export function disableAutomaticScrolling(): void {
  return window.scrollTo(0, 1);
}
