/**
 * Constante
 */
export const CONST = {
  CURRENT_YEAR: new Date().getFullYear(),
  PAGE_PRODUCTLIST: "https://portail.rf-elearning-cac.com/",
  PAGE_RF_COMPTABLE: "https://rfcomptable.grouperf.com",
  KEYCLOAK: {
    URL: process.env.REACT_APP_KEYCLOAK_URL || "",
    REALM: process.env.REACT_APP_KEYCLOAK_REALM || "",
    CLIENTID: process.env.REACT_APP_KEYCLOAK_CLIENTID || ""
  },
  TIME_ENDPOINT: process.env.REACT_APP_TIME_ENPOINT || "",
  OKULUS_URL:
    process.env.REACT_APP_OKULUS_URL ||
    "https://diy7ta1tt6jst.cloudfront.net/preprod/okulus",
  RF_LEARNING_URL: process.env.REACT_APP_URL_RF_LEARNING,
  GRAPHQL_ENDPOINT:
    process.env.REACT_APP_GRAPHQL_ENDPOINT || "http://localhost:4000/graphql",
  ALICE_ADAPTER_ENDPOINT:
    process.env.REACT_APP_ALICE_ADAPTER_ENDPOINT || "http://localhost:3002",
  REPORT_ENDPOINT:
    process.env.REACT_APP_REPORT_ENDPOINT || "http://localhost:3003",
  AKMAIL_SENDRAW_ENDPOINT:
    process.env.REACT_APP_AKMAIL_SENDRAW_ENDPOINT ||
    "http://preprod.mail.internal.kreactive.eu/sendraw",
  ROUTES: {
    HOME: "/home",
    MONTH_MODULE: "/module-du-mois",
    MODULES: "/modules",
    DASHBOARD: "/mon-profil",
    CERTIFICATE: "/attestations",
    CONTACT: "/contact",
    FAQ: "/faq",
    MENTIONS: "/mentions-legales"
  },
  GA_ID: process.env.REACT_APP_GA_ID || "UA-110057889-X",
  SURVEY_ID: process.env.REACT_APP_SURVEY_ID || "Wsv3c4ai"
};

export const ROUTES = [
  { ID: 0, PATH: CONST.ROUTES.HOME },
  { ID: 1, PATH: CONST.ROUTES.MONTH_MODULE },
  { ID: 2, PATH: CONST.ROUTES.MODULES },
  { ID: 3, PATH: CONST.ROUTES.DASHBOARD },
  { ID: 4, PATH: CONST.ROUTES.CERTIFICATE },
  { ID: 5, PATH: CONST.ROUTES.CONTACT },
  { ID: 6, PATH: CONST.ROUTES.FAQ },
  { ID: 7, PATH: CONST.ROUTES.MENTIONS }
];
