export enum colorCAC {
  "black" = "#18202f",
  "anthracite" = "#273C45",
  "grayLight" = "#f1f4f5",
  "grayCard" = "#FCFCFC",
  "greenRF" = "#77C35E",
  "greenKaitoke" = "#00442C",
  "greenDark" = "#006e49",
  "greenLight" = "#7fbc4d",
  "green" = "#7fbc4d",
  "orange" = "#f39200",
  "purple" = "#82368c",
  "rose" = "#e6007e",
  "yellow" = "#f9cc00",
  "red" = "#e64330",
  "redQuiz" = "#ee3547"
}
