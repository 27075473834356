import { Type } from "../models/activity.model";
import { colorCAC } from "../styles/color";

/**
 * Get background of type activity
 * @param type
 */
export const getBackgroundTypeActivity = (type: Type): string => {
  switch (type) {
    case Type.FicheInfo:
      return colorCAC.orange;
    case Type.Video:
      return colorCAC.greenDark;
    case Type.Annonce:
      return colorCAC.black; // TODO: set correct color
    case Type.QuizThematique:
      return colorCAC.greenDark;
    case Type.QuizFinal:
      return colorCAC.rose;
    case Type.Categorisation:
      return colorCAC.greenLight;
    case Type.Analyse:
      return colorCAC.purple;
    default:
      return "white";
  }
};

/**
 * Get bilan color
 * @param success
 */
export const handleBilanColor = (success: number): colorCAC => {
  return success > 75
    ? colorCAC.greenRF
    : success >= 50 && success <= 75
    ? colorCAC.orange
    : colorCAC.redQuiz;
};
