// React
import React from "react";

// Material
import {
  Grid,
  createStyles,
  makeStyles,
  Theme,
  CircularProgress
} from "@material-ui/core";

// Colors
import { colorCAC } from "../../styles/color";
import { grey } from "@material-ui/core/colors";

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapperContent: {
      position: "relative"
    },
    circulartext: {
      position: "absolute",
      top: 20,
      width: 60,
      height: 30,
      textAlign: "center",
      display: "block",
      color: theme.palette.primary.main,
      zIndex: 2
    },
    circularBackground: {
      color: grey[300],
      zIndex: 1
    },
    circular: {
      position: "absolute",
      color: theme.palette.primary.main,
      zIndex: 2
    }
  })
);
/**
 * TimeProgressCard props
 */
interface ITimeProgressCardProps {
  progress: number;
  isExpired?: boolean;
}
/** TimeProgressCard */
const TimeProgressCard = (props: ITimeProgressCardProps) => {
  const classes = useStyles();
  const { progress, isExpired } = props;

  return (
    <Grid item={true}>
      <div className={classes.wrapperContent}>
        {isExpired ? (
          <>
            <span
              className={classes.circulartext}
              style={{ color: colorCAC.red }}
            >
              Archivé
            </span>
            <CircularProgress
              size={60}
              variant="determinate"
              value={100}
              className={classes.circularBackground}
              thickness={1}
              style={{ color: colorCAC.red }}
            />
          </>
        ) : (
          <>
            <span className={classes.circulartext}>{progress}%</span>
            <CircularProgress
              size={60}
              variant="determinate"
              value={progress}
              thickness={4}
              className={classes.circular}
            />
            <CircularProgress
              size={60}
              variant="determinate"
              value={100}
              className={classes.circularBackground}
            />
          </>
        )}
      </div>
    </Grid>
  );
};

export default React.memo(TimeProgressCard);
