// Material
import { LinearProgress } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

/**
 * Border linear progress
 */
export const BorderLinearProgress = withStyles({
  root: {
    width: 50,
    height: 4,
    borderRadius: 20,
    backgroundColor: "#dddfdf"
  },
  bar: {
    borderRadius: 20
  }
})(LinearProgress);
