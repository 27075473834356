import { Quiz } from "../graphql";
import { Type } from "../models/activity.model";

/**
 * Add necessary HTML class to add CSS (see activity.scss for more detail)
 * @param activity
 */
export const formatLessonSummaryContent = (summaryContent: string): string => {
  const parser = new DOMParser();
  const el = parser.parseFromString(summaryContent, "text/html")
    .documentElement;

  const item = el.getElementsByTagName("div")[0];
  item.classList.add("item");

  const summary = el.getElementsByTagName("div")[1];
  summary.classList.add("summary");

  const content = el.getElementsByTagName("div")[2];
  content.classList.add("content");

  return el.querySelector("body")!.innerHTML;
};

/**
 * Is final quiz
 * @param quiz
 */
export const isFinalQuiz = (quiz: Quiz): boolean => {
  return !!quiz.tags && quiz.tags.includes(Type.QuizFinal);
};
