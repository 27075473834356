import { Reducer } from "redux";
import { currentYearTimeActions, STORE_TIME, ADD_TIME } from "./actions";

const reducer: Reducer<number, currentYearTimeActions> = (
  state = 0,
  action
) => {
  switch (action.type) {
    case STORE_TIME:
      return action.payload;
    case ADD_TIME:
      return state + action.payload;
    default:
      return state;
  }
};

export { reducer as currentYearTimeReducer };
