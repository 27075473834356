import React from "react";
import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";

const GreenCheckbox = withStyles({
  root: {
    color: "#7FBC4D",
    "&$checked": {
      color: "#7FBC4D"
    }
  },
  checked: {}
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

export default GreenCheckbox;
