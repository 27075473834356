// React
import React, { useState, useRef, useEffect } from "react";
import { withRouter } from "react-router";

// Material ui
import {
  Container,
  Grid,
  Paper,
  makeStyles,
  Theme,
  createStyles,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Fab,
  Box
} from "@material-ui/core";

// Libs
import Helmet from "react-helmet";
import { useSnackbar } from "notistack";

// Icons
import { ArrowForward } from "@material-ui/icons";

// Color
import { colorCAC } from "../../styles/color";

// Service
import { ReportService, useContactPostMutation } from "../../graphql";

const CONTACT_TYPES = [
  {
    type: "Echange avec le référent pédagogique",
    service: ReportService.GrfPedagogique
  },
  {
    type: "Demande d'assistance produit",
    service: ReportService.GrfCommercial
  },
  {
    type: "Informations prise en charge OPCO",
    service: ReportService.GrfCommercial
  },
  { type: "Commandes et abonnements", service: ReportService.GrfCommercial },
  { type: "Réglements et facturation", service: ReportService.GrfCommercial },
  { type: "Coordonnées et adresses", service: ReportService.GrfCommercial },
  {
    type: "Identifiants (code d'accès et mot de passe)",
    service: ReportService.GrfCommercial
  },
  { type: "Autres", service: ReportService.GrfCommercial }
];

const INITIAL_CONTACT = {
  type: CONTACT_TYPES[0].type,
  service: CONTACT_TYPES[0].service
};

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      [theme.breakpoints.down("sm")]: {
        padding: 0
      }
    },
    title: {
      color: "#FFFFFF",
      backgroundColor: colorCAC.greenDark,
      paddingLeft: 6,
      paddingRight: 6,
      fontWeight: "bold"
    },
    paper: {
      padding: theme.spacing(2),
      paddingBottom: theme.spacing(5),
      textAlign: "center",
      color: theme.palette.text.secondary
    },
    textField: {
      width: "100%"
    },
    formControl: {
      minWidth: 150,
      width: "100%"
    },
    pagerNavigation: {
      transform: "translateY(-50%)"
    },
    button: {
      color: "white",
      textTransform: "none",
      fontSize: 16,
      [theme.breakpoints.up("md")]: {
        margin: 10
      }
    }
  })
);

/**
 * Contact Component
 */
const Contact = () => {
  /** Classes  */
  const classes = useStyles();
  /** Graphql */
  const [postContact] = useContactPostMutation();
  /** Contact form */
  const [contact, setContact] = useState<{
    email?: string;
    message?: string;
    type: string;
    service: ReportService;
  }>({ ...INITIAL_CONTACT });
  const [errors, setErrors] = useState<{ email?: string; message?: string }>(
    {}
  );

  const inputLabel = useRef<HTMLLabelElement>(null);
  const [labelWidth, setLabelWidth] = useState(0);

  /** use snackbar */
  const { enqueueSnackbar } = useSnackbar();

  /**
   * useEffect
   */
  useEffect(() => {
    if (inputLabel.current) {
      setLabelWidth(inputLabel.current!.offsetWidth);
    }
  }, [inputLabel]);

  /**
   * Validate mail
   * @param email
   */
  const validateEmail = (email: string) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return re.test(email.toLowerCase());
  };

  /**
   * Change contact form values
   */
  const onChange = (field: string, value: string) => {
    if (field === "type") {
      const contactType = CONTACT_TYPES.find(c => c.type === value);
      if (contactType) {
        setContact({
          ...contact,
          type: contactType.type,
          service: contactType.service
        });
      }
    } else {
      setContact({ ...contact, [field]: value });
    }
  };

  /**
   * Change contact form values
   */
  const onBlur = (field: string, value: string) =>
    setErrors(
      field === "message"
        ? {
            email: errors.email,
            message: !value ? "Veuillez compléter votre demande" : undefined
          }
        : {
            email: validateEmail(value)
              ? undefined
              : "Votre adresse email n'est pas valide",
            message: errors.message
          }
    );

  /**
   * Send contact message
   */
  const sendMessage = () => {
    if (contact.message && contact.email && validateEmail(contact.email)) {
      postContact({
        variables: {
          service: contact.service,
          email: contact.email,
          subType: contact.type,
          message: contact.message
        }
      }).then(() => {
        setContact({ ...INITIAL_CONTACT });
        enqueueSnackbar("Votre message a bien été envoyé !", {
          variant: "success"
        });
      });
    }
  };

  return (
    <React.Fragment>
      <Helmet defer={false}>
        <meta charSet="utf-8" />
        <title>Contactez-nous - RF e-Learning CAC</title>
      </Helmet>
      <Container maxWidth="md" className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Grid container spacing={3}>
                <Grid item xs={"auto"}>
                  <Box fontSize="h6.fontSize" className={classes.title}>
                    CONTACTEZ-NOUS
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextField
                    id="outlined-email-input"
                    label="Email"
                    className={classes.textField}
                    type="email"
                    name="email"
                    autoComplete="email"
                    margin="normal"
                    variant="outlined"
                    placeholder="email@domain.com"
                    value={contact.email}
                    error={!!errors.email}
                    helperText={errors.email}
                    onChange={event => onChange("email", event.target.value)}
                    onBlur={event => onBlur("email", event.target.value)}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                    margin="normal"
                  >
                    <InputLabel
                      ref={inputLabel}
                      htmlFor="outlined-contact-type-simple"
                    >
                      Nature de la demande
                    </InputLabel>
                    <Select
                      value={contact.type}
                      onChange={event =>
                        onChange("type", event.target.value as string)
                      }
                      input={
                        <OutlinedInput
                          labelWidth={labelWidth}
                          name="contact-type"
                          id="outlined-contact-type-simple"
                        />
                      }
                    >
                      {CONTACT_TYPES.map((contact, index) => (
                        <MenuItem key={index} value={contact.type}>
                          {contact.type}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    id="outlined-message-input"
                    label="Message"
                    className={classes.textField}
                    type="text"
                    name="message"
                    margin="normal"
                    variant="outlined"
                    multiline
                    rows="4"
                    placeholder="Votre message ici"
                    value={contact.message}
                    error={!!errors.message}
                    helperText={errors.message}
                    onChange={event => onChange("message", event.target.value)}
                    onBlur={event => onBlur("message", event.target.value)}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <Grid item={true} xs={true} className={classes.pagerNavigation}>
          <Grid container={true} justify="center" alignItems="center">
            <Fab
              variant="extended"
              size="large"
              color="primary"
              className={classes.button}
              onClick={() => sendMessage()}
            >
              Envoyer&nbsp;
              <ArrowForward />
            </Fab>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default withRouter(React.memo(Contact));
