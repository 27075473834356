// React
import React, { useEffect, useRef, useState } from "react";
// moment
import moment from "moment";
// material UI
import {
  Box,
  Chip,
  createStyles,
  makeStyles,
  Typography
} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
// variable
import { colorCAC } from "../../styles/color";
import { useDispatch } from "react-redux";
import { addToCurrentYearTime } from "../../store/currentYearTime/actions";

/**
 * Interface Timer
 */
interface ITimer {
  initialTime: number;
  activityTime: number;
  isResponsive: boolean;
  canPlayTimer: boolean;
}

/**
 * Styles
 */
const useStyles = makeStyles(() =>
  createStyles({
    chip: {
      borderRadius: 20,
      width: 105,
      backgroundColor: "white"
    },
    btgroup: {
      height: 40,
      marginRight: 10
    },
    txtFinished: {
      color: colorCAC.greenRF
    },
    icFinished: {
      color: colorCAC.greenRF
    },
    icNotFinished: {
      color: "#eaeced",
      "&:hover": {
        color: "#eaeced"
      }
    }
  })
);

const Timer = ({
  initialTime,
  activityTime,
  isResponsive,
  canPlayTimer
}: ITimer) => {
  /** Classes  **/
  const classes = useStyles();

  /** Time states */
  const [isFinished, setFinished] = useState<boolean>(
    initialTime >= activityTime
  );
  const [time, setTime] = useState<number>(
    initialTime >= activityTime ? activityTime : initialTime
  );
  const [counter, setCounter] = useState<number>(0);

  const timerInterval = useRef<null | NodeJS.Timeout>(null);
  const dispatch = useDispatch();

  const currentTimeFormat = moment.utc(time * 1000).format("HH:mm:ss");

  const stop = () => {
    if (counter) {
      dispatch(addToCurrentYearTime(counter));
    }
    timerInterval && clearInterval(timerInterval.current!);
  };

  /**
   * startCounter
   */
  const start = () => {
    if (!isFinished) {
      setCounter(0);
      timerInterval.current = setInterval(() => {
        setCounter(counter => {
          return counter + 1;
        });
        setTime(time => {
          return time + 1;
        });
      }, 1000);
    }
  };

  /**
   * Use effect init start date when timer is started or finished
   */
  useEffect(() => {
    canPlayTimer ? start() : stop();
  }, [canPlayTimer]);

  /**
   * Use effect when leaving page where timer is displayed
   */
  useEffect(() => {
    return stop;
  }, []);

  /**
   * Use effect checked if timer is finished
   */
  useEffect(() => {
    if (time >= activityTime) {
      setFinished(true);
      stop();
    }
  }, [time]);

  /**
   * Use effect send time to currentYearTime every minute
   */
  useEffect(() => {
    if (counter > 30) {
      dispatch(addToCurrentYearTime(counter));
      setCounter(0);
    }
  }, [counter]);

  const iconValidity = (
    <CheckCircleIcon
      className={isFinished ? classes.icFinished : classes.icNotFinished}
    />
  );

  return !isResponsive ? (
    <Chip
      label={currentTimeFormat}
      className={`${classes.btgroup} ${classes.chip} ${
        isFinished ? classes.txtFinished : ""
      }`}
      variant="outlined"
      onDelete={() => {
        return;
      }}
      deleteIcon={iconValidity}
    />
  ) : (
    <Box m={2} style={{ display: "flex" }}>
      <Typography
        variant="body1"
        gutterBottom={true}
        className={isFinished ? classes.txtFinished : ""}
      >
        {currentTimeFormat}
      </Typography>
      <CheckCircleIcon
        className={isFinished ? classes.icFinished : classes.icNotFinished}
      />
    </Box>
  );
};

export default React.memo(Timer);
