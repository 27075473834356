import { action, ActionType } from "typesafe-actions";

export const STORE_TIME = "STORE CURRENT YEAR TIME";
export const ADD_TIME = "ADD TO CURRENT YEAR TIME";

export const storeCurrentYearTime = (time: number) => {
  return action(STORE_TIME, time);
};

export const addToCurrentYearTime = (time: number) => {
  return action(ADD_TIME, time);
};

const allActions = {
  storeCurrentYearTime: storeCurrentYearTime,
  addToCurrentYearTime: addToCurrentYearTime
};

export type currentYearTimeActions = ActionType<typeof allActions>;
