import { Type } from "../models/activity.model";

/**
 * titleCase modify string HELLO WORLD to Hello World
 * @param str
 */
export const titleCase = (str: string): string => {
  return str
    .split(" ")
    .map(item => item.charAt(0).toUpperCase() + item.slice(1).toLowerCase())
    .join(" ");
};

/**
 * Format type activity label
 * @param type
 */
export const formatterTypeActivity = (type: Type): string => {
  switch (type) {
    case Type.FicheInfo:
      return "Mémo";
    case Type.Video:
      return "Vidéo";
    case Type.Annonce:
      return "Annonce";
    case Type.QuizThematique:
      return "Quiz thématique";
    case Type.QuizFinal:
      return "Quiz final";
    case Type.Categorisation:
      return "Catégorisation";
    case Type.Analyse:
      return "Analyse";
    default:
      return type;
  }
};

/**
 * Is plurial
 * @param nbActivity
 */
export const isPlurial = (nbActivity: number): string => {
  return nbActivity > 1 ? "activités" : "activité";
};

/**
 * return formatted description
 * @param moduleDescription
 * @param nbLines
 * @param endThreeDots
 */
export const getFormattedModuleDescription = (
  moduleDescription: string,
  nbLines: number = 2,
  endThreeDots: boolean = true
): string => {
  let formattedModuleDescription: string[] = moduleDescription.split(
    /<\/li>|<\/p>|<br \/>/
  );

  const descriptionEnd =
    formattedModuleDescription.length > nbLines && endThreeDots
      ? "\n" + "..."
      : "";

  return (
    formattedModuleDescription.slice(0, nbLines).join("<br/>") + descriptionEnd
  );
};

/**
 * return formatted description
 * @param moduleDescription
 */
export const getRawModuleDescription = (moduleDescription: string): string => {
  return moduleDescription
    .split(/<\/li>|<\/p>|<br \/>/)
    .filter(line => !!line)
    .map(line => line.replace(/<\/?li>|<\/?p>|<br \/>/g, ""))
    .join("");
};
